.background {
  height: 104vh;
  left: -2vw;
  overflow: hidden;
  position: fixed;
  top: -2vh;
  width: 104vw;

  canvas {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
