.hide-on-mobile {
  @include mq($bp-xsmall, max) {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }
}

.hide-on-tablet {
  @include mq($bp-medium) {
    @include mq($bp-large, max) {
      display: none !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

.hide-on-desktop {
  @include mq($bp-large) {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }
}
