@import 'variables';

.c {
  @each $name, $color in $colors {

    &-#{$name} {
      color: $color;
    }
  }
}

.bc {
  @each $name, $color in $colors {

    &-#{$name} {
      background-color: $color;
    }
  }
}

.fill {
  @each $name, $color in $colors {

    &-#{$name} {
      fill: $color;
    }
  }
}
