.figure {
  max-width: 100%;
  text-align: center;

  &--left {
    float: left;
    max-width: 50%;
  }

  &--right {
    float: right;
    max-width: 50%;
  }

  &--center {

    > img {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--rounded {
    display: inline-block;

    img {
      border-radius: $border-radius;
      overflow: hidden;
      width: 100%;
    }
  }
}

img {
  display: block;
  max-width: 100%;
}

figcaption {
  color: _($colors, 'grey-700');
  font-size: 90%;
}
