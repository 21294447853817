.link,
a {
  color: _($colors, 'white');
  transition: color $transition-duration $transition-timing;

  &:hover {
    color: _($hover-colors, 'brand-1');
  }
}

.link {
  text-decoration: underline;
}

.zoom-in {
  transition: all $transition-duration $transition-timing;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(1);
  }
}

.move-up {
  transition: all $transition-duration $transition-timing;

  &:hover {
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
}
