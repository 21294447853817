.list {

  &--numbers {
    list-style: decimal;

    li {
      color: _($colors, 'brand-1');
      margin-left: $gutter * 2;
      padding-left: $gutter / 1;
    }
  }

  &--bullets {
    list-style-type: disc;

    li {
      color: _($colors, 'brand-1');
      margin-left: $gutter * 2;
      padding-left: $gutter / 2;
    }
  }

  &--icons {

    li {
      margin-bottom: $gutter / 2;
      padding-left: $gutter * 2.5;
      position: relative;

      .icon-holder {
        background: _($colors, 'grey-800');
        border-radius: 50%;
        content: '';
        display: block;
        height: 28px;
        left: 0;
        position: absolute;
        top: -2px;
        width: 28px;
      }

      .icon {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &--inline {

    li {
      display: inline-block;
    }
  }
}

.logos {

  li {
    font-size: 0;
  }

  img {
    max-width: 80px;
  }
}
