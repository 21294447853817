$Hbackgrounds: (
  linear-gradient(to bottom, #020111 85%, #191621 100%),
  linear-gradient(to bottom, #020111 60%, #20202c 100%),
  linear-gradient(to bottom, #020111 10%, #3a3a52 100%),
  linear-gradient(to bottom, #20202c 0%, #515175 100%),
  linear-gradient(to bottom, #40405c 0%, #8a76ab 100%),
  linear-gradient(to bottom, #4a4969 0%, #cd82a0 100%),
  linear-gradient(to bottom, #757abf 0%, #eab0d1 100%),
  linear-gradient(to bottom, #82addb 0%, #ebb2b1 100%),
  linear-gradient(to bottom, #94c5f8 0%, #b1b5ea 100%),
  linear-gradient(to bottom, #96c5f8 0%, #94dfff 100%),
  linear-gradient(to bottom, #90dffe 0%, #67d1fb 100%),
  linear-gradient(to bottom, #90dffe 0%, #38a3d1 100%),
  linear-gradient(to bottom, #57c1eb 0%, #246fa8 100%),
  linear-gradient(to bottom, #3c4447 0%, #1e528e 100%),
  linear-gradient(to bottom, #2473ab 0%, #5b7983 100%),
  linear-gradient(to bottom, #1e528e 0%, #9da671 100%),
  linear-gradient(to bottom, #1e528e 0%, #e9ce5d 100%),
  linear-gradient(to bottom, #154277 0%, #b26339 100%),
  linear-gradient(to bottom, #163c52 0%, #2f1107 100%),
  linear-gradient(to bottom, #071b26 0%, #240e03 100%),
  linear-gradient(to bottom, #010a10 0%, #2f1107 100%),
  linear-gradient(to bottom, #020111 0%, #191621 100%),
  linear-gradient(to bottom, #020111 0%, #150800 100%),
  linear-gradient(to bottom, #00000c 0%, #000000 100%)
);

.bg {
  bottom: -100vh;
  left: -100vw;
  position: fixed;
  right: -100vw;
  top: -100vh;
  z-index: -1;

  &::after,
  &::before {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  &::after {
    z-index: 2;
  }

  @for $i from 1 through 24 {

    &--H#{$i} {

      &::after {
        background: nth($Hbackgrounds, $i);
      }
    }
  }
}
