.normal {
  font-weight: 400;
}

.strong,
strong {
  font-weight: 900;
}

.italic,
em {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}

.no-underline {
  text-decoration: none;
}

.uppercase {
  text-transform: uppercase;
}
