@import '../../node_modules/reset-css/sass/_reset.scss';
@import 'mixins/',
  '0_basics/';

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  -webkit-text-size-adjust: 100%; /* stylelint-disable-line property-no-vendor-prefix */
}

body {
  margin: 0;
  overflow-x: hidden;
}

.site {
  overflow-x: hidden;
}

@import '1_atoms/',
  '2_molecules/',
  '3_organisms/',
  '4_templates/';
