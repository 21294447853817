.opacity {

  &-0 {
    opacity: 0;
  }

  @for $i from 1 through 10 {

    &-0#{$i} {
      opacity: $i / 10;
    }
  }

  &-1 {
    opacity: 1;
  }
}
