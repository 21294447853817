.main {
  box-sizing: border-box;
  flex-direction: column;
  max-width: 600px;
  min-height: 100vh;
  position: relative;
  z-index: 2;
}

.content {
  flex: 0 0 100%;
  max-width: 100%;
}

.logo {
  max-width: 280px;
  width: 60%;
}

.liquid-title {
  height: 64px;
  position: relative;
  transform: scale(.3);

  canvas {
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
  }

  @include mq($bp-xsmall) {
    transform: scale(.4);
    height: 96px;
  }

  @include mq($bp-medium) {
    transform: scale(.6);
    height: 108px;
  }
}
