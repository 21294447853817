.icon {
  display: inline-block;
  height: 2px;
  position: relative;
  top: -1px;
  user-select: none;
  vertical-align: middle;
  width: 16px;

  > svg {
    // transition: all $transition-duration $transition-timing;
    display: block;
    max-height: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 96%;
  }

  $icon-sizes: 18, 24, 32, 44, 72;
  $icons: (
    1: nth($icon-sizes, 1),
    2: nth($icon-sizes, 2),
    3: nth($icon-sizes, 3),
    4: nth($icon-sizes, 4),
    5: nth($icon-sizes, 5)
  );

  @each $icon, $size in $icons {

    &--size-#{$icon} {
      height: $size + px;
      width: $size + px;

      > svg {
        max-height: $size + px;
      }
    }
  }
}
