.form {

  &__row {
    display: flex;
    flex-wrap: nowrap;
    min-height: 36px;

    @include mq($bp-small, max) {

      &--responsive {
        flex-wrap: wrap;

        .col {
          flex: 0 0 100%;
          margin-bottom: $gutter / 4;
          max-width: 100%;
        }
      }
    }
  }

  &__label {
    line-height: 1.5;
  }

  &__field {

    &--standard,
    &--standard-break {
      text-align: right;

      input[type='text'],
      input[type='email'],
      input[type='password'],
      input[type='number'],
      input[type='tel'],
      select {
        height: 36px;
        line-height: 36px;
        margin-left: $gutter / 4;
        max-width: 100%;
        min-width: inherit;
        width: auto;

        &.interacted {

          &:required:valid {
            background-image: none;
          }

          &:required:invalid {
            background-image: none;
            border-color: _($colors, 'status-error');
          }
        }
      }
    }

    &--standard-break {
      @include mq($bp-small, max) {
        text-align: left;

        input[type='text'],
        input[type='email'],
        input[type='password'],
        input[type='number'],
        input[type='tel'],
        select {
          margin-left: 0;
          width: 100%;
        }
      }
    }

    &--inline {
      text-align: center;

      input[type='text'],
      input[type='email'],
      input[type='password'],
      input[type='number'],
      input[type='tel'],
      select {
        height: 36px;
        line-height: 36px;
        margin-left: $gutter / 2;
        margin-right: $gutter / 2;
        min-width: inherit;
        width: auto;

        @include mq($bp-small, max) {
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
}
