$font-sizes: 14, 20, 28, 36, 54, 72;
$sizes: (
  1: nth($font-sizes, 1),
  2: nth($font-sizes, 2),
  3: nth($font-sizes, 3),
  4: nth($font-sizes, 4),
  5: nth($font-sizes, 5),
  6: nth($font-sizes, 6)
);

@each $textsize, $tsize in $sizes {

  .size#{$textsize} {
    @include font-size($tsize);
  }
}

@each $bptype, $bpshort in $breakpoints-types {
  @each $textsize, $tsize in $sizes {
    @include mq($bptype) {

      .size#{$textsize}#{$bpshort} {
        @include font-size($tsize);
      }
    }
  }
}
