// Breakpoints

$bp-xsmall:      480;
$bp-small:       600;
$bp-medium:      768;
$bp-large:       1024;
$bp-xlarge:      1280;
$bp-site-width:  1660;

$breakpoints-types: (
  $bp-xsmall: '-xs',
  $bp-small: '-s',
  $bp-medium: '-m',
  $bp-large: '-l',
  $bp-xlarge: '-xl'
);

// Measures

$gutter: 20px;
$margin-bottom: 20px;
$border-radius: 20px;

// Animations

$transition-duration: 150ms;
$transition-delay: 100ms;
$transition-timing: ease-in;
