@import 'family',
  'helpers',
  'lineheight',
  'sizes',
  'textalign',
  'variations';

$font-default: 'League Gothic', sans-serif;
$font-default-size: nth($font-sizes, 2);
$font-default-weight: 200;
$font-default-lineheight: nth($line-sizes, 1);

body {
  @include font-size($font-default-size);

  color: _($colors, 'white');
  font-family: $font-default;
  font-weight: $font-default-weight;
  line-height: $font-default-lineheight;
}

::selection {
  background: _($colors, 'brand-1');
  border: 1px solid _($colors, 'brand-2');
  color: _($colors, 'white');
}
