// Colors
$colors: (
  'black': #000000,
  'brand-1': #00a0d7,
  'brand-2': #cad008,
  'brand-3': #e84f23,
  'grey-100': #eee,
  'grey-200': #ddd,
  'grey-300': #bbb,
  'grey-400': #aaa,
  'grey-500': #999,
  'grey-600': #777,
  'grey-700': #333,
  'grey-800': #222,
  'status-error': #e41b13,
  'status-info': #fff2af,
  'status-ok': #6abc0c,
  'white': #ffffff
);

$hover-colors: (
  'brand-1': #066686,
  'brand-2': #7a7e07,
  'brand-3': #8a2a10
);

// Global colors
$color-body:    _($colors, 'grey-800');
$color-text:    _($colors, 'white');
$color-border:  _($colors, 'grey-300');
