@import 'flex',
  'grid',
  'hide',
  'margins',
  'paddings';

.hide {
  display: none;
}

.block {
  display: block;
}
