.h {

  &-breakword {
    word-break: break-word;
  }

  &-nowrap {
    white-space: nowrap;
  }

  &-textoverflow {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  &-noselect {
    user-select: none;
  }
}
